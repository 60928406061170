import { FC, ReactNode } from "react";
import styled from "styled-components";

const FormLabelContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;

  hr {
    width: 1px;
    height: 14px;
    display: block;
    border: none;
    margin: 0 7px;
    background: #cccccc;
  }
`;
const LabelSpan = styled.span`
  color: var(--main-color);
  font-size: 16px;
  font-weight: 500;
`;
const ChildrenDiv = styled.div`
  display: flex;
  font-size: 14px;
  padding-left: 5px;
`;

const FormLabel: FC<{
  label?: string;
  text: string;
  rightChildren?: ReactNode;
}> = ({ label, text, rightChildren }) => {
  return (
    <FormLabelContainer>
      {label && (
        <>
          <LabelSpan>{label}</LabelSpan>
          <hr />
        </>
      )}
      <ChildrenDiv>
        <span>{text}</span>
        {rightChildren}
      </ChildrenDiv>
    </FormLabelContainer>
  );
};

export default FormLabel;
