import { useQuery } from "react-query";
import client from "../client";
import { ServiceDB } from "../types";
import { krwFormat } from "../utils";

const useServices = (option?: { includeWon?: boolean }) => {
  return useQuery<ServiceDB[]>("services", async () => {
    const res = await client.get("/service/services");
    if (option?.includeWon) {
      const newServices = res.data.map((service: ServiceDB) => {
        return {
          ...service,
          text: `${service.text} - ${krwFormat(service.point, {
            useWon: true,
          })} [1개당]`,
        };
      });
      return newServices.reverse();
    }
    return res.data;
  });
};

export default useServices;
