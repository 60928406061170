import { FC, useEffect, useState } from "react";
import { EventEmitter } from "events";
import styled, { keyframes } from "styled-components";
import Lottie from "react-lottie";
import birdLottie from "./assets/loading/bird-lottie.json";
import ReactLoading from "react-loading";
import { useIsFetching, useIsMutating, useQueryClient } from "react-query";

const lottileAnimation = keyframes`
    0% {
        transform:translateX(300px);
    }

    100% {
        transform:translateX(-300px);
    }
`;

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  backdrop-filter: blur(4px);
  background: rgba(0, 0, 0, 0.3);
  transition: 300ms all;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  &.hide {
    opacity: 0;
    background: transparent;
    backdrop-filter: none;
    pointer-events: none;
  }
`;
const LottiePoistion = styled.div`
  position: absolute;
  top: 5%;
  left: 50%;
  transform: translateX(-50%);
`;
const LottieAnimation = styled.div`
  animation: ${lottileAnimation} 3s linear infinite;
`;
const StyledReactLoading = styled(ReactLoading)``;

const event = new EventEmitter();

export const showLoading = () => {
  event.emit("show");
};
export const hideLoading = () => {
  event.emit("hide");
};

const Loading: FC = () => {
  const [show, setShow] = useState<boolean>(false);
  const lottieOptions = {
    loop: true,
    autoplay: true,
    animationData: birdLottie,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  useEffect(() => {
    event.on("show", () => setShow(true));
    event.on("hide", () => setShow(false));
  }, []);

  return (
    <Container className={show ? "show" : "hide"}>
      <StyledReactLoading type="bubbles" width={100} height={100} />
    </Container>
  );
};

export default Loading;
