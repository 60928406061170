import { useMutation, UseMutationOption } from "react-query";
import client from "../client";

const useCreateApiKey = (option?: UseMutationOption) => {
  return useMutation(async () => {
    const res = await client.post("/auth/api-key");
    return res.data;
  }, option);
};

export default useCreateApiKey;
