import { doesNotThrow } from "assert";
import { ChangeEvent, FC, FormEvent, useCallback, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";
import FormLabel from "../components/FormLabel";
import {
  AppSelectBox,
  BorderBox,
  DefaultLayout,
  FormButton,
  FormInput,
  SectionTitle,
} from "../components/styles";
import { ClassName } from "../GlobalStyles";
import useRegister from "../hooks/useRegister";
import { checkLength } from "../utils";

const CaptchaWrap = styled.div`
  display: flex;
  justify-content: center;
`;

const FormInputWrapper = styled.div`
  margin-top: 50px;

  > * + * {
    margin-top: 15px;
  }
`;

interface FormState {
  id: string;
  password: string;
  passwordRe: string;
  email: string;
  registerWhere: string;
}

const RegisterPage: FC = () => {
  const navigate = useNavigate();
  const [formState, setFormState] = useState<FormState>({
    id: "",
    password: "",
    email: "",
    passwordRe: "",
    registerWhere: "",
  });
  const { mutate: registerApi } = useRegister({
    onSuccess: async () => {
      toast.success("회원가입에 성공했습니다.");
      navigate("/login");
    },
    onError: (err) => {
      switch (err?.response?.status) {
        case 400: {
          toast.error("입력 양식을 맞춰주세요");
          return;
        }
        case 409: {
          toast.error("존재하는 아이디 입니다.");
          return;
        }
        default: {
          toast.error("오류가 발생했습니다.");
          return;
        }
      }
    },
  });

  const changeFormState = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setFormState((prev) => ({ ...prev, [name]: value }));
  }, []);

  const changeRegisterWhere = useCallback(
    (e: ChangeEvent<HTMLSelectElement>) => {
      const { value } = e.target;

      setFormState((prev) => ({ ...prev, registerWhere: value }));
    },
    []
  );

  const submitHandler = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (formState.id.length === 0) {
      toast.error("아이디를 입력해 주세요");
      return;
    }
    if (!formState.email.includes("@") || !formState.email.includes(".")) {
      toast.error("이메일 형식을 맞춰주세요");
      return;
    }
    if (formState.password.length === 0) {
      toast.error("비밀번호를 입력해 주세요");
      return;
    }
    if (formState.password !== formState.passwordRe) {
      toast.error("비밀번호 재확인이 다릅니다");
      return;
    }
    if (formState.registerWhere === "") {
      toast.error("가입경로를 선택해주세요");
      return;
    }

    registerApi(formState);
  };

  return (
    <DefaultLayout>
      <form onSubmit={submitHandler}>
        <BorderBox className={ClassName.shadow01}>
          <SectionTitle>회원가입</SectionTitle>
          <FormInputWrapper>
            <div>
              <FormLabel text="아이디" />
              <FormInput
                onChange={changeFormState}
                name="id"
                type="text"
                value={formState.id}
                placeholder="ID"
              />
            </div>
            <div>
              <FormLabel text="이메일 (비밀번호 분실 시 사용)" />
              <FormInput
                onChange={changeFormState}
                name="email"
                type="text"
                value={formState.email}
                placeholder="Email"
              />
            </div>
            <div>
              <FormLabel text="비밀번호" />
              <FormInput
                onChange={changeFormState}
                name="password"
                type="password"
                value={formState.password}
                placeholder="Password"
              />
            </div>
            <div>
              <FormLabel text="비밀번호 재확인" />
              <FormInput
                onChange={changeFormState}
                name="passwordRe"
                type="password"
                value={formState.passwordRe}
                placeholder="Password"
              />
            </div>
            <div>
              <FormLabel text="가입경로" />
              <AppSelectBox onChange={changeRegisterWhere}>
                <option></option>
                <option>구글</option>
                <option>네이버</option>
                <option>페이스북</option>
                <option>카카오톡</option>
                <option>번개장터</option>
                <option>인스타그램</option>
                <option>셀클럽</option>
                <option>모바일앱</option>
                <option>기타</option>
              </AppSelectBox>
            </div>
          </FormInputWrapper>
          <FormButton type="submit">회원가입</FormButton>
        </BorderBox>
      </form>
    </DefaultLayout>
  );
};

export default RegisterPage;
