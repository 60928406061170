import { FC } from "react";
import styled from "styled-components";
import { BorderBox, DefaultLayout } from "../components/styles";
import { ClassName } from "../GlobalStyles";

const Container = styled.div`
  margin-top: 60px;
`;
const PageTitle = styled.div`
  font-size: 28px;
  white-space: pre-wrap;
  text-align: center;
  b {
    font-weight: 600;
    color: #000;
  }
`;
const SectionTitle = styled.div`
  font-size: 20px;
  font-weight: 500;
`;
const SectionContainer = styled.div`
  margin-bottom: 25px;
`;
const SectionContent = styled.div`
  white-space: pre-wrap;
  font-size: 14px;
  margin-top: 3px;
  margin-left: 5px;
  line-height: 1.5;
`;

const RankGuidePage: FC = () => {
  return (
    <Container>
      <DefaultLayout>
        <PageTitle>
          <b>매출을 올려주는</b>
          {"\n"}
          인스타그램 인기게시물 등록 Tip
        </PageTitle>
        <BorderBox className={ClassName.shadow01}>
          <SectionContainer>
            <SectionTitle>게시물 업로드 TIP</SectionTitle>
            <SectionContent>
              - 모바일로 사진을 업로드하고, 10분 이내 신규게시물 작업을
              권장합니다.{"\n"}- 여러장의 사진보다는 '사진과 동영상 각1개씩'
              올리시길 권장합니다.{"\n"}- 과거 등록한 사진을 중복으로 업로드하지
              마세요.{"\n"}- 매일 꾸준히 게시물을 올려주세요.(1~2개){"\n"}- 관련
              해시태그의 피드에 소통을 권합니다.(좋아요, 댓글 등){"\n"}- 실제
              팔로워(한국인 팔로워)의 꾸준한 유입을 권합니다.{"\n"}- 위와 같이
              게시물 관리가 잘될 경우, 인기게시물에 노출되기 쉬워집니다.
            </SectionContent>
          </SectionContainer>
          <SectionContainer>
            <SectionTitle>해시태그 활용 TIP</SectionTitle>
            <SectionContent>
              - 해시태그는 직접 입력해주세요.(붙여넣기 금지){"\n"}- 갯수는 10개
              ~ 20개 사이로 입력해주세요.{"\n"}- 관련없는 해시태그 동시 입력
              금지.(예: #맛집 #미용실){"\n"}- 계정의 브랜드에 어울리도록
              해시태그를 사용하시길 권합니다.{"\n"}- 게시물 수가 적은(5천개
              이하) 해시태그를 3개 정도 포함시켜주세요.
            </SectionContent>
          </SectionContainer>
          <SectionContainer>
            <SectionTitle>서비스 구매 TIP</SectionTitle>
            <SectionContent>
              - 실제 한국인 팔로워 서비스를 이용하시길 권합니다.{"\n"}- 한번에
              대량 구매를 하시기 보단 100명 내외로 꾸준히 구매하시길 권합니다.
              {"\n"}- 위 사항을 지켜주신다면 계정 점수를 높이는데에 도움이 될
              것입니다.
            </SectionContent>
          </SectionContainer>
        </BorderBox>
      </DefaultLayout>
    </Container>
  );
};

export default RankGuidePage;
