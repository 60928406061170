import { useQuery, UseQueryOption } from "react-query";
import client from "../client";

const useProfile = (option?: UseQueryOption) => {
  return useQuery(
    "profile",
    async () => {
      const res = await client.get("/auth/profile");
      return res.data;
    },
    option
  );
};

export default useProfile;
