import { FC } from "react";
import styled from "styled-components";
import { BorderBox, DefaultLayout } from "../components/styles";
import { ClassName } from "../GlobalStyles";

const Container = styled.div``;
const StyledBorderBox = styled(BorderBox)`
  text-align: center;

  & + & {
    margin-top: 35px;
  }

  img {
    width: 100%;
    border-radius: 12px;
  }
`;

const LinkTitle = styled.div`
  font-size: 20px;
  white-space: pre-wrap;
  font-weight: 500;

  span {
    color: #ff0000;
  }
`;
const LinkText = styled.div`
  font-size: 35px;
  font-weight: 500;
  margin-bottom: 25px;
`;

const LinkGuidePage: FC = () => {
  return (
    <Container>
      <DefaultLayout>
        <StyledBorderBox className={ClassName.shadow01}>
          <LinkTitle>
            인스타그램 팔로워 상품의 경우{"\n"}
            인스타그램 계정 (아이디)만 입력하시면 됩니다{"\n"}
            <span>예)instagram</span>
          </LinkTitle>
        </StyledBorderBox>
        <StyledBorderBox className={ClassName.shadow01}>
          <LinkText>링크 입력 가이드 - 모바일</LinkText>
          <img src="https://assets.hipstagram.co.kr/img/img_link_01.svg" />
        </StyledBorderBox>
        <StyledBorderBox className={ClassName.shadow01}>
          <LinkText>링크 입력 가이드 - PC</LinkText>

          <img src="https://assets.hipstagram.co.kr/img/img_link_02.svg" />
        </StyledBorderBox>
      </DefaultLayout>
    </Container>
  );
};

export default LinkGuidePage;
