import styled from "styled-components";

export const DefaultLayout = styled.div`
  max-width: 880px;
  margin: 0 auto;
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
`;

export const AppSelectBox = styled.select`
  -webkit-appearance: none;
  width: 100%;
  padding: 0 20px;
  height: 40px;
  outline: none;
  border: none;
  background: #f8f8f8;
  transition: 300ms all;
  border-radius: 20px;

  &:focus {
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(102 175 233 / 60%);
  }
`;

export const FormInput = styled.input`
  height: 40px;
  outline: none;
  border: none;
  background: #f8f8f8;
  transition: 300ms all;
  border-radius: 20px;
  padding: 0 20px;
  width: 100%;
  color: #555;
  box-sizing: border-box;

  &:focus {
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(102 175 233 / 60%);
  }
  &:disabled {
    cursor: not-allowed;
    background-color: #e5e5e5;
  }

  @media (max-width: 880px) {
    padding: 0 10px;
  }
`;

export const FormButton = styled.button`
  padding: 10px 0px;
  width: 100%;
  margin-top: 15px;
  color: rgb(255, 255, 255);
  font-weight: 700;
  border-radius: 8px;
  border: none;
  font-size: 17px;
  transition: 300ms all;
  background-color: var(--main-color);

  &:hover {
    background-color: var(--main-hover-color);
  }

  &:disabled {
    cursor: not-allowed;
    color: grey;
    background-color: #e5e5e5;
  }
`;

export const BorderBox = styled.div`
  padding: 40px;
  border-radius: 14px;
  margin-top: 30px;

  @media (max-width: 880px) {
    padding: 20px 10px;
  }
`;

export const SectionTitle = styled.div`
  font-size: 28px;
  text-align: center;
  font-weight: 700;
`;

export const TermContainer = styled.div`
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  padding: 50px 15px;
  box-sizing: border-box;
`;

export const TermTitle = styled.div`
  font-size: 24px;
`;
export const TermContent = styled.div`
  line-height: inherit;

  h3 {
    font-size: 15px;
    margin: 0;
    margin-top: 30px;
  }
  p {
    font-size: 12px;
    margin: 4px 0;
    margin-left: 20px;
  }
`;
