import { useQuery, UseQueryOption } from "react-query";
import client from "../client";
import { CategoryDB } from "../types";

const useCategory = (option?: UseQueryOption) => {
  return useQuery<CategoryDB[]>(
    "categorys",
    async () => {
      const res = await client.get("/service/categorys");
      return res.data.reverse();
    },
    option
  );
};

export default useCategory;
