import { useQuery, UseQueryOption } from "react-query";
import client from "../client";
import { PaymentDB } from "../types";

const usePointHistory = (option?: UseQueryOption) => {
  return useQuery<PaymentDB[]>(
    "point-history",
    async () => {
      const response = await client.get("/service/point-history");
      return response.data;
    },
    option
  );
};

export default usePointHistory;
