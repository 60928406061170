export enum PointChangeType {
  ORDER,
  PAYMENT,
  REFUND_PARTIAL,
  REFUND_CANCELED,
  ADMIN_EDIT,
}

export enum PaymentFeeType {
  CASH,
  FEE,
  NONE,
}

export enum PaymentStatus {
  PENDING,
  SUCCESS,
  FAIL,
}
