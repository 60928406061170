import { useEffect, useState } from "react";

const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 1200);

  useEffect(() => {
    const resizeHandler = () => {
      setIsMobile(window.innerWidth <= 1200);
    };
    window.addEventListener("resize", resizeHandler);

    return () => {
      window.removeEventListener("resize", resizeHandler);
    };
  }, []);

  return isMobile;
};

export default useIsMobile;
