import {
  ChangeEvent,
  FC,
  FormEvent,
  useCallback,
  useRef,
  useState,
} from "react";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";
import FormLabel from "../components/FormLabel";
import {
  BorderBox,
  DefaultLayout,
  FormButton,
  FormInput,
  SectionTitle,
} from "../components/styles";
import { ClassName } from "../GlobalStyles";
import useLogin from "../hooks/useLogin";

const FormInputWrapper = styled.div`
  margin-top: 50px;

  > * + * {
    margin-top: 15px;
  }
`;

const IdSaveWrapper = styled.div`
  display: flex;
  align-items: center;

  input {
    cursor: pointer;
  }

  span {
    margin-left: 3px;
    font-size: 14px;
    cursor: pointer;
    user-select: none;
  }
`;

interface FormState {
  id: string;
  password: string;
}

const LoginPage: FC = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const idSaveRef = useRef<HTMLInputElement>(null);
  const defaultSavedId = window.localStorage.getItem("savedId");
  const defaultChecked = defaultSavedId !== null;
  const [formState, setFormState] = useState<FormState>({
    id: defaultSavedId ?? "",
    password: "",
  });

  const { mutate: loginApi } = useLogin({
    onSuccess: async (data) => {
      if (idSaveRef.current!.checked) {
        window.localStorage.setItem("savedId", formState.id);
      } else {
        window.localStorage.removeItem("savedId");
      }
      const { accessToken } = data;
      window.localStorage.setItem("jwt", accessToken);
      await queryClient.refetchQueries("profile");
      toast.success("로그인에 성공했습니다.");
      navigate("/order");
    },
    onError: (err) => {
      switch (err?.response?.status) {
        case 400: {
          toast.error("입력 양식을 맞춰주세요.");
          return;
        }
        case 404: {
          toast.error("아이디 또는 비밀번호가 다릅니다.");
          return;
        }
        default: {
          toast.error("오류가 발생했습니다.");
          return;
        }
      }
    },
  });

  const submitHandler = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (formState.id.length === 0) {
      toast.error("아이디를 입력 해주세요.");
      return;
    }
    if (formState.password.length === 0) {
      toast.error("비밀번호를 입력 해주세요.");
      return;
    }

    loginApi({ id: formState.id, password: formState.password });
  };
  const changeFormState = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setFormState((prev) => ({ ...prev, [name]: value }));
  }, []);

  return (
    <DefaultLayout>
      <form onSubmit={submitHandler}>
        <BorderBox className={ClassName.shadow01}>
          <SectionTitle>로그인</SectionTitle>
          <FormInputWrapper>
            <div>
              <FormLabel text="아이디" />
              <FormInput
                onChange={changeFormState}
                name="id"
                type="text"
                value={formState.id}
                placeholder="ID"
              />
            </div>
            <div>
              <FormLabel text="비밀번호" />
              <FormInput
                onChange={changeFormState}
                name="password"
                type="password"
                value={formState.password}
                placeholder="Password"
              />
            </div>
            <IdSaveWrapper>
              <label>
                <input
                  defaultChecked={defaultChecked}
                  ref={idSaveRef}
                  type="checkbox"
                />
                <span>아이디 저장</span>
              </label>
            </IdSaveWrapper>
          </FormInputWrapper>
          <FormButton type="submit">로그인</FormButton>
        </BorderBox>
      </form>
    </DefaultLayout>
  );
};

export default LoginPage;
