import { useMutation, UseMutationOption } from "react-query";
import client from "../client";
import { PaymentFeeType } from "../types/enum";

const useCreatePoint = (option?: UseMutationOption) => {
  return useMutation(
    async ({
      name,
      point,
      feeValue,
      feeType,
    }: {
      name: string;
      point: number;
      feeValue: Object;
      feeType: PaymentFeeType;
    }) => {
      await client.post("/service/point", {
        name,
        point,
        feeValue,
        feeType,
      });
    },
    option
  );
};

export default useCreatePoint;
