import { FC, useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import { dateFormat } from "../utils";

const FadeInAnimation = keyframes`
  0% {
    width:0;
  }
  100% {
    width:149px;
  }
`;
const FadeInMobileAnimation = keyframes`
  0% {
    width:0;
  }
  100% {
    width:103px;
  }
`;
const Container = styled.div`
  padding: 13px 0;
  background: var(--main-color);
  color: white;
  text-align: center;
  font-size: 16px;
  display: flex;
  align-items: center;
  border-radius: 12px;
  justify-content: center;

  @media (max-width: 1150px) {
    font-size: 11px;
  }
`;
const Stamp = styled.div`
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  animation: ${FadeInAnimation} 1300ms forwards ease;
  animation-delay: 500ms;
  margin-left: 3px;
  width: 0px;

  @media (max-width: 1150px) {
    animation: ${FadeInMobileAnimation} 1300ms forwards ease;
  }
`;

const ServiceCheck: FC = () => {
  const [stamp, setStamp] = useState(Date.now());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setStamp(Date.now());
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);
  return (
    <Container>
      [서비스 정상 가동중] 체크시간 : <Stamp>{dateFormat(stamp)}</Stamp>
    </Container>
  );
};

export default ServiceCheck;
