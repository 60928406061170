import { useMutation, UseMutationOption } from "react-query";
import client from "../client";

const useCreateOrder = (option?: UseMutationOption) => {
  return useMutation(
    "order",
    async ({
      serviceId,
      link,
      amount,
    }: {
      serviceId: number;
      link: string;
      amount: number;
    }) => {
      await client.post("/service/order", {
        serviceId,
        link,
        amount,
      });
    },
    option
  );
};

export default useCreateOrder;
