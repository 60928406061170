import { useMutation, UseMutationOption } from "react-query";
import client from "../client";

const useChangePassword = (option?: UseMutationOption) => {
  return useMutation(
    async ({
      currentPassword,
      newPassword,
    }: {
      currentPassword: string;
      newPassword: string;
    }) => {
      await client.post("/auth/change-password", {
        currentPassword,
        newPassword,
      });
    },
    option
  );
};

export default useChangePassword;
