import { FC, useEffect } from "react";

const LogoutPage: FC = () => {
  useEffect(() => {
    window.localStorage.removeItem("jwt");
    window.location.href = "/";
  }, []);
  return <div></div>;
};

export default LogoutPage;
