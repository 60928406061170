import { useMutation, UseMutationOption } from "react-query";
import client from "../client";

const useLogin = (option?: UseMutationOption) => {
  return useMutation(
    async ({ id, password }: { id: string; password: string }) => {
      const res = await client.post("/auth/login", {
        id,
        password,
      });

      return res.data;
    },
    option
  );
};

export default useLogin;
