import { useQuery, UseQueryOption } from "react-query";
import client from "../client";
import { OrderDB } from "../types";

const useOrderHistory = (option?: UseQueryOption) => {
  return useQuery<OrderDB[]>(
    "order-history",
    async () => {
      const res = await client.get("/service/order-history");
      return res.data;
    },
    option
  );
};

export default useOrderHistory;
