import { useEffect, useState } from "react";

const useViewPort = (callback: (value: number) => boolean) => {
  const [isMobile, setIsMobile] = useState<boolean>(
    callback(window.innerWidth)
  );

  useEffect(() => {
    const resizeHandler = () => {
      setIsMobile(callback(window.innerWidth));
    };
    window.addEventListener("resize", resizeHandler);

    return () => {
      window.removeEventListener("resize", resizeHandler);
    };
  }, []);

  return isMobile;
};

export default useViewPort;
