import Hamburger from "hamburger-react";
import { FC, useState } from "react";
import styled from "styled-components";
import useIsMobile from "../hooks/useIsMobile";

const Container = styled.div`
  padding-top: 10px;
  padding-bottom: 8px;
  font-size: 14px;
  width: 100%;
  color: white;
  text-align: center;
  position: relative;
  background-color: #1f55b1;
`;

const HambugerWrap = styled.div`
  position: absolute;
  top: 50%;
  right: 10px;

  > div {
    width: 26px !important;
    height: 26px !important;
  }
`;

const WidthModeNotify: FC = () => {
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const isMobile = useIsMobile();
  return isMobile && isOpen ? (
    <Container>
      화면을 가로모드로 사용하시면 편리합니다.
      <HambugerWrap>
        <Hamburger onToggle={setIsOpen} size={16} toggled={true} />
      </HambugerWrap>
    </Container>
  ) : null;
};

export default WidthModeNotify;
