import { useMutation, UseMutationOption } from "react-query";
import client from "../client";

const useRegister = (option?: UseMutationOption) => {
  return useMutation(
    async ({
      id,
      password,
      email,
      registerWhere,
    }: {
      id: string;
      password: string;
      email: string;
      registerWhere: string;
    }) => {
      await client.post("/auth/register", {
        id,
        password,
        email,
        registerWhere,
      });
    },
    option
  );
};

export default useRegister;
