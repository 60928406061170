import { PaymentStatus } from "./types/enum";

export const dateFormat = (stamp: number) => {
  const dateObj = new Date(stamp);

  const year = dateObj.getFullYear();
  const month = (dateObj.getMonth() + 1).toString().padStart(2, "0");
  const date = dateObj.getDate().toString().padStart(2, "0");
  const hour = dateObj.getHours().toString().padStart(2, "0");
  const minute = dateObj.getMinutes().toString().padStart(2, "0");
  const second = dateObj.getSeconds().toString().padStart(2, "0");

  return `${year}-${month}-${date} ${hour}:${minute}:${second}`;
};

export const krwFormat = (num: number, option?: { useWon?: boolean }) => {
  return new Intl.NumberFormat(
    "ko-KR",
    option?.useWon
      ? {
          style: "currency",
          currency: "KRW",
          minimumFractionDigits: 0,
          maximumFractionDigits: 1,
        }
      : undefined
  ).format(num);
};

export const checkLength = (
  value: string,
  { min, max }: { min: number; max: number }
) => {
  const { length } = value;

  if (length < min) return false;
  if (length > max) return false;

  return true;
};

export const pointStatusFormat = (status: PaymentStatus) => {
  switch (status) {
    case PaymentStatus.PENDING:
      return "대기";
    case PaymentStatus.SUCCESS:
      return "수락";
    case PaymentStatus.FAIL:
      return "거절";
  }
};

export const getInstaLink = (value: string) => {
  try {
    const url = new URL(value);
    return url.toString();
  } catch (err) {
    return `https://instagram.com/${value}`;
  }
};

export const orderStatusFormat = (status: string) => {
  switch (status) {
    case "CREATED":
      return "주문접수";
    case "PENDING":
      return "주문접수";
    case "PROCESSING":
      return "주문대기";
    case "PARTIAL":
      return "주문실패(작업되지 않은 만큼 환불)";
    case "IN PROGRESS":
      return "주문준비중 또는 가동중";
    case "CANCELED":
      return "주문취소 및 전액 환불";
    case "ZOG8_CANCELED":
      return "주문중 비공개 변경(환불불가)";
    case "COMPLETED":
      return "주문완료";
  }
};

export const removeCuntryEmoji = (str: string, replaceString?: string) => {
  const countryCodeRegExps: [RegExp, string][] = [
    [/\uD83C\uDDF0\uD83C\uDDF7/g, "\uD83D\uDC6B\uD83C\uDFFB"],
    [/\uD83C\uDDFA\uD83C\uDDF8/g, "\uD83D\uDC6B"],
  ];

  return countryCodeRegExps.reduce(
    (state, regexp) => state.replace(regexp[0], replaceString ?? ""),
    str
  );
};

export const orderStartCountFormat = (startCount: number) => {
  const num = Number(startCount);

  if (isNaN(num)) return "";
  if (num < 0) return "";

  return krwFormat(num);
};
